import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  onNavigateTerms() {
    const url =
      "https://www.coca-cola.com/cl/es/legal/terms-of-service";
    window.open(url, "_self");
  }

  onNavigatePrivacy() {
    const url =
      "https://www.coca-cola.com/cl/es/legal/privacy-policy";
    window.open(url, "_self");
  }
}
