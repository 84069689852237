<div id="home" class="bloque_blanco">
  <div class="container_total">

      <div class="frame_left">
        <img class="guirnaldas-img" src="assets/cy-images/desktop_deco_navidad.png" alt="" srcset="">
        <div class="logo_container">
          <img class="logo" src="assets/cy-images/logo.png" alt="Logo Coca-Cola" srcset="">
        </div>
        <div class="text_container">
          <h1 class="slogan">
            Tus bebidas favoritas de la familia <br>
            Coca-Cola directo a la casa
          </h1>
        </div>
        <div class="content_container">
          <div class="select_container">
            <div class="select_dropbox-container">
              <!-- !Aqui el dropdown funcionalidad-->
              <div class="container_select">
                <app-dropdown
                  [cities]="cities"
                  (valueEmit)="out($event)"
                ></app-dropdown>
              </div>
              <div class="btn_container">
                <button class="redirect_btn" (click)="redirect()">
                  COMPRA AQUÍ
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="banner_container">
          <img src="assets/cy-images/desktop_papanoel_navidad.png" alt="" srcset="">
        </div>
      </div>
      <div class="frame_right">
        <img src="assets/cy-images/desktop_productos_navidad.png" alt="" srcset="">
      </div>
  </div>
</div>


<!-- ** ORIGINAL ** -->
<!-- <div id="home" class="bloque_blanco">
  <div class="background_cover">
    <div class="estructura_bloque_interno">
      <div class="logo_container">
        <img
          class="logo"
          src="assets/logo-coke.jpg"
          alt="Logo Coca-Cola"
        />
      </div>
      <div class="bloque_grilla text_container">
        <h1 class="slogan">
          Tus bebidas favoritas de <br class="br-mb"> la familia Coca-Cola, <br>
          <span class="span_slogan">directo a tu casa</span>
        </h1>
      </div>
      <div class="bloque_grilla content_container">
        <div class="select_container">
          <p class="select_text">Selecciona tu ciudad para ingresar:</p>
          <div class="select_dropbox-container"> -->
            <!-- !Aqui el dropdown funcionalidad-->
            <!-- <div class="container_select">
              <app-dropdown
                [cities]="cities"
                (valueEmit)="out($event)"
              ></app-dropdown>
            </div>
            <div class="btn_container">
              <button class="redirect_btn" (click)="redirect()">
                Ir al sitio
              </button>
            </div>
          </div>
        </div>
        <div class="img_container">
          <img
            class="img_principal"
            src="assets/bodegon-mcc.png"
            alt="imagen productos Coca-Cola Company"
          />
        </div>
      </div>
    </div>
  </div>
</div> -->
