import { Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalComponentComponent } from './modal-component/modal-component.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { CityService } from './services/city.service';
import { updateItemByName } from 'src/app/utils/array-utils';
declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('modal', { static: false }) modal: ModalComponentComponent;

  openModal = false;
  google: any;
  lat;
  lng;
  address;
  location;
  // locality;
  oldcities: any = [];
  cities: any = [];
  form: FormGroup;
  agreed = 0;
  disagreed = 0;
  currentCityId: any;

  public savedLocation;

  andinaCityRedirect = [
    '15',
    '16',
    '17',
    '19',
    '20'
  ];

  chileCitiesName = [
    "Arica",
    "Iquique",
    "Papudo",
    "Cachagua",
    "Zapallar",
    "Maitencillo",
    "Puchuncaví",
    "La Laguna",
    "Viña del Mar",
    "Quilpué",
    "Valparaiso",
    "Talca",
    "Chillán",
    "Concepción",
    "Los Ángeles",
    "Temuco",
    "Villarrica",
    "Pucón",
    "Puerto Varas",
    "Puerto Montt",
    "Santiago",
    "Coquimbo",
    "La Serena",
    "Guanaqueros",
    "Guanaquero",
    "Las Tacas",
    "Machalí",
    "Morrillos",
    "Playa Blanca",
    "Puerto Velero",
    "Rancagua",
    "Tongoy",
    "Totoralillo",
  ];

  citiesUnited = [
    "Cachagua",
    "Zapallar",
    "Puchuncaví",
    "Maitencillo",
    "La Laguna",
  ];

  andinaCities = [
    { id: '15', name: 'Santiago' },
    { id: '16', name: 'Coquimbo' },
    { id: '17', name: 'La Serena' },
    { id: '19', name: 'Rancagua' },
    { id: '20', name: 'Machalí' },
  ];

  constructor(
    public httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private cityService: CityService
  ) {
    this.form = this.formBuilder.group({
      cities: [],
    });
  }

  ngOnInit(): void {
    this.setCities();
    /**
     * @todo conseguir las credenciales de googlemaps para poder implemetnar a futuro la feature. O definir alcance y eliminarla completamente.
     * */
    //this.locate();
  }

  // Fired is location is enabled by user
  public locate(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;

        const geocoder = new google.maps.Geocoder();
        const location = new google.maps.LatLng(this.lat, this.lng);
        const request = { location };

        geocoder.geocode(request, (results) => {
          const newArrayProvince = results.reverse();
          const province: any = newArrayProvince.find(data => {
            if (data.types[0] === 'colloquial_area') {
              return data.types[0] === 'colloquial_area';
            } else if (data.types[0] === 'locality') {
              return data.types[0] === 'locality';
            } else if (data.types[0] === 'political') {
              return data.types[0] === 'political';
            } else if (data.types[0] === 'administrative_area_level_3') {
              return data.types[0] === 'administrative_area_level_3';
            } else if (data.types[0] === 'plus_code') {
              return data.types[0] === 'plus_code';
            }
          });

          const cityNameFinded = province.address_components.find(city => {
            return this.chileCitiesName.includes(city.short_name);
          });

          if (cityNameFinded) {
            this.currentCityId = this.cities.find(item => {
              if (this.citiesUnited.includes(cityNameFinded.short_name)) {
                let nameCityUnited: any = '';
                if (cityNameFinded.short_name === 'Maitencillo' ||
                  cityNameFinded.short_name === 'Puchuncaví' ||
                  cityNameFinded.short_name === 'La Laguna') {
                  nameCityUnited = 'Maitencillo - Laguna';
                } else {
                  nameCityUnited = 'Cachagua - Zapallar';
                }
                return item.name === nameCityUnited;
              }
              return item.name.includes(cityNameFinded.short_name);
            })?.googleEmbedId;
          }

          if (!this.currentCityId && cityNameFinded) {
            const andinaCity = this.andinaCities.find(res => {
              return res.name.includes(cityNameFinded.short_name);
            });
            this.currentCityId = andinaCity.id;
          }

          this.cityService.selectedCityId.subscribe(value => {
            this.currentCityId = value;
          });
          if (this.currentCityId !== undefined) {
            this.redirectToPage();
          }

        });
      });
    }
  }

  // Fired when user enters again 
  public redirect(): void {
    if (localStorage.getItem('savedLocation')) {
      const zones = this.cities.flatMap(({ zones }) => zones);
      const currentCity = zones.find(
        (item) => item.id === +localStorage.getItem('savedLocation')
      );
      if (currentCity) {
        gtag('event', currentCity.name, {
          event_category: 'Redirect',
          event_label: 'Prehome Chile',
        });
        console.log(`Se envió ${currentCity.name} como ciudad elegida.`);
        // window.open('http://embonor.micoca-cola.cl', '_self');
      } else {
        this.savedLocation = localStorage.getItem('savedLocation');
        if (this.andinaCityRedirect.includes(this.savedLocation)) {
          const currentAndinaCity = this.andinaCities.find(res => {
            return this.savedLocation === res.id;
          })
          gtag('event', currentAndinaCity.name.replace(' de Chile', ''), {
            event_category: 'Home',
            event_label: 'Prehome Chile'
          });
          console.log(`Se envió ${currentAndinaCity.name} como ciudad elegida.`);
          localStorage.setItem('savedLocation', currentAndinaCity.id.toString());
          // window.open('http://andina.micoca-cola.cl/', '_self');
        }
      }
    }
  }

  setCities(): void {
    this.cities.push(
      {
        "id": 2,
        "internalName": 'ARICA Y PARINACOTA',
        "name": 'Arica y Parinacota',
        "zones": [
          {
            "id": 1,
            "internalName": 'ARICA',
            "name": 'Arica',
            "googleEmbedId": "1p5qT2gRFxwJvl4i-ekKJcMQHifWSPulN"
          }
        ]
      },
      {
        "id": 3,
        "internalName": 'TARAPACA',
        "name": 'Tarapacá',
        "zones": [
          {
            "id": 2,
            "internalName": 'IQUIQUE',
            "name": 'Iquique',
            "googleEmbedId": "1W0-kCKv_mOJ_knY0S_1octOKx3U2SNSd"
          },
          {
            "id": 3,
            "internalName": 'ALTO HOSPICIO',
            "name": 'Alto Hospicio',
            "googleEmbedId": "1W0-kCKv_mOJ_knY0S_3octOKx3U2SNSd"
          }
        ]
      },
      {
        "id": 5,
        "internalName": 'VALPARAISO',
        "name": 'Valparaíso',
        "zones": [
          {
            "id": 6,
            "internalName": 'CONCON',
            "name": 'Concón',
            "googleEmbedId": "13UpVfxpGGnndQySURApsaRZ1Vl2xV-YV"
          },
          {
            "id": 5,
            "internalName": 'QUILPUE',
            "name": 'Quilpué',
            "googleEmbedId": "146_k7hCIN0fh4vNXGQh76NI_gNSxY1dh"
          },
          {
            "id": 53,
            "internalName": 'VALPARAISO',
            "name": 'Valparaiso',
          },
          {
            "id": 4,
            "internalName": 'VINA DEL MAR',
            "name": 'Viña del Mar',
            "googleEmbedId": "13UpVfxpGGnndQySURApsaRZ1Vl3xV-YV"
          }
        ]
      },
      {
        "id": 7,
        "internalName": 'MAULE',
        "name": 'Maule',
        "zones": [
          {
            "id": 14,
            "internalName": 'CURICO',
            "name": 'Curicó',
            "googleEmbedId": "1oir4SDM_dWNbEO4t40ZytqHgDWqtdy9Q"
          },
          {
            "id": 13,
            "internalName": 'MAULE',
            "name": 'Maule',
            "googleEmbedId": "1tNBDnPsfNSFX2zILONJmTqcN1SHJT-Sa"
          },
          {
            "id": 38,
            "internalName": 'SAN JAVIER',
            "name": 'San Javier',
            "googleEmbedId": "1VbMdNfVaZktrQVxPe5lcpW_r7iJBOxk"
          },
          {
            "id": 12,
            "internalName": 'TALCA',
            "name": 'Talca',
            "googleEmbedId": "1tNBDnPsfNSFX2zILONJmTqcN2SHJT-Sa"
          }
        ]
      },
      {
        "id": 8,
        "internalName": 'NUBLE',
        "name": 'Ñuble',
        "zones": [
          {
            "id": 28,
            "internalName": 'CHILLAN',
            "name": 'Chillán',
            "googleEmbedId": "1VwEfeQZsuVc4j9dmY0a504ogOugckuvb"
          },
          {
            "id": 27,
            "internalName": 'BULNES',
            "name": 'Bulnes',
            "googleEmbedId": "1VwEfeQZsuVc4j9dmY0a604ogOugckuvb"
          }
        ]
      },
      {
        "id": 9,
        "internalName": 'BIO BIO',
        "name": 'Bío Bío',
        "zones": [
          {
            "id": 24,
            "internalName": 'CONCEPCION',
            "name": 'Concepción',
            "googleEmbedId": "1B2b7_cyBjGqlRO5hvioyOXWBQbzZzlLA"
          },
          {
            "id": 18,
            "internalName": 'LOS ANGELES',
            "name": 'Los Ángeles',
            "googleEmbedId": "17-4VbCi3sIcdyFI_EVaTBr2VMfJ9NWQi"
          },
          {
            "id": 21,
            "internalName": 'SAN PEDRO DE LA PAZ',
            "name": 'San Pedro de la Paz',
            "googleEmbedId": "1B2b7_cyBjGqlRO6hvioyOXWBQbzZzlLA"
          },
          {
            "id": 52,
            "internalName": 'CORONEL',
            "name": 'Coronel',
            "googleEmbedId": "1B2b7_cyBjGqlRO7hvioyOXWBQbzZzlLA"
          },
          {
            "id": 29,
            "internalName": 'HUALPEN',
            "name": 'Hualpen',
            "googleEmbedId": "1B2b7_cyBjGqlRO8hvioyOXWBQbzZzlLA"
          },
          {
            "id": 25,
            "internalName": 'TALCAHUANO',
            "name": 'Talcahuano',
            "googleEmbedId": "1B2b7_cyBjGqlRO9hvioyOXWBQbzZzlLA"
          },
          {
            "id": 85,
            "internalName": 'CHIGUAYANTE',
            "name": 'Chiguayante',
            "googleEmbedId": "1B2b7_cyBjGqlR10hvioyOXWBQbzZzlLA"
          },
          {
            "id": 26,
            "internalName": 'TOME',
            "name": 'Tome',
            "googleEmbedId": "1B2b7_cyBjGqlR11hvioyOXWBQbzZzlLA"
          }
        ]
      },
      {
        "id": 10,
        "internalName": 'ARAUCANIA',
        "name": 'Araucanía',
        "zones": [
          {
            "id": 34,
            "internalName": 'TEMUCO',
            "name": 'Temuco',
            "googleEmbedId": "1iFluDclWqsd6nbtanelaxn0Fs6q_uK2k"
          }
        ]
      },
      {
        "id": 11,
        "internalName": 'LOS LAGOS',
        "name": 'Los Lagos',
        "zones": [
          {
            "id": 35,
            "internalName": 'PUERTO MONTT',
            "name": 'Puerto Montt',
            "googleEmbedId": "1Un3gDkeSJXCaJ2TpWckbqJW43-RBP3vR"
          },
          {
            "id": 36,
            "internalName": 'PUERTO VARAS',
            "name": 'Puerto Varas',
            "googleEmbedId": "1dwKPSfDk4VDrFIRe0RvYyOXPBOgo6v25"
          }
        ]
      }
    )
    this.redirect();
  }

  onVoted(agreed: boolean): void {
    agreed ? this.agreed++ : this.disagreed++;
  }

  // Fired by locate() method is location is enabled
  redirectToPage(): void {
    this.cityService.changeCity(this.currentCityId);
    const zones = this.cities.flatMap(({ zones }) => zones);
    const currentCity = zones.find(
      (item) => item.googleEmbedId === this.currentCityId
    );
    gtag('event', 'Geolocalizacion', {
      event_category: 'Ubicacion correcta',
      event_label: 'Prehome Chile'
    });
    if (currentCity) {
      gtag('event', currentCity.name, {
        event_category: 'Automatic Redirection',
        event_label: 'Prehome Chile'
      });
      console.log(`Se envió ${currentCity.name} como ciudad elegida.`);
      localStorage.setItem('savedLocation', currentCity.id);
      window.open(`http://embonor.micoca-cola.cl/?city=${currentCity.name}`, '_self');
    } else {
      if (this.andinaCityRedirect.includes(this.currentCityId)) {
        const currentAndinaCity = this.andinaCities.find(res => {
          return this.currentCityId === res.id;
        })
        gtag('event', currentAndinaCity.name.replace(' de Chile', ''), {
          event_category: 'Home',
          event_label: 'Prehome Chile'
        });
        console.log(`Se envió ${currentAndinaCity.name} como ciudad elegida.`);
        localStorage.setItem('savedLocation', this.currentCityId);
        window.open('http://andina.micoca-cola.cl/', '_self');
      }
    }
  }
}
